.timeline {
  height: 100%;
  border-left: 1px solid red;
  overflow: hidden;
  //   padding-top: 300px;

  background: rgba(white, 0.5);
}
.timeline__item {
  height: 50px;
}
.timeline__items {
  padding-top: calc(50vh - 100px);
  padding-bottom: calc(50vh - 100px);
}
.selection-area {
  border: 1px solid green;
  border-right: 0;
  border-left: 0;
  width: 100%;
  position: absolute;
  top: calc(50% - 100px);
  height: 100px;
}
