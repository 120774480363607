$desktop: 1024px;

html {
}
.page-container {
  position: relative;
}
.page-container__map {
}
.page-container__timeline {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 400px;
}
